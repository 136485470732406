<template>
  <div>
    <!-- Filter -->
    <b-button
      class="btn-filter-appoitment btn-icon rounded-circle"
      variant="gradient-warning"
      @click="
        () => {
          filterbycode = '';
          isActiveFilter = true;
        }
      "
    >
      <feather-icon icon="FilterIcon" size="25" />
    </b-button>

    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isActiveFilter"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isActiveFilter">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Filtros</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-form class="px-1 mt-1">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Empresas">
                <v-select
                  v-model="companieSelected"
                  :loading="loadingCompanies"
                  @search="getCompanies"
                  :options="companies"
                  :clearable="false"
                  searchable
                  multiple
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Projetos">
                <v-select
                  v-model="projectSelected"
                  :options="projects"
                  :clearable="false"
                  @search="getProjects"
                  :loading="loadingProjects"
                  multiple
                  searchable
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Professor">
                <v-select
                  v-model="teachersSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="teachers"
                  autocomplete="off"
                  @search="getTeachers"
                  searchable
                  :loading="loadingTeacher"
                  :clearable="false"
                  multiple
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Situação">
                <v-select
                  v-model="statusSelected"
                  class="select-pending"
                  :options="status"
                  autocomplete="off"
                  :clearable="false"
                  multiple
                />
              </b-form-group>
            </b-col>

            <b-col cols="9">
              <b-button @click="filter" variant="gradient-info" block>
                FILTRAR
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button @click="filterClear" variant="gradient-primary" block>
                Limpar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>

    <viewcard--c title="Solicitações de Agendamento" :btnew="btnew">
      <view--c
        permission="permission.appointment.requests.view"
        :busy="isloading"
      >
        <Table
          :fields="fields"
          :list="
            !isloading && list[currentePage] ? list[currentePage].itens : []
          "
          @orderBy="getRecordsOrderBy"
          border="full"
          responsive
        >
          <template #teacher="data">
            {{ data.item.teacher ? data.item.teacher : "--" }}
          </template>

          <template #project="data">
            {{ data.item.project ? data.item.project : "--" }}
          </template>

          <template #subjects="data">
            <div v-if="data.item.subjects && data.item.subjects.length > 0">
              <div
                v-for="(sub, id) in data.item.subjects"
                :key="id"
                style="font-size: 11px"
              >
                <p
                  style="max-width: 300px"
                  class="m-0 text-truncate"
                  v-if="id < 10"
                >
                  {{ sub }}
                </p>
              </div>
              <span v-if="data.item.subjects.length > 10">...</span>
            </div>
            <div v-else>--</div>
          </template>

          <template #limit_date="data">
            {{
              data.item.limit_date
                ? data.item.limit_date
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("/")
                : null
            }}
          </template>

          <template #appointment_ref_id="data">
            <div>
              <div
                v-if="data.item.appointment_ref_id > 0"
                @click="
                  $router.push({
                    name: 'pedagogic-calendar',
                    query: {
                      q: data.item.appointment_ref_id,
                    },
                  })
                "
              >
                <b>#{{ data.item.appointment_ref_id }}</b>
              </div>
              <span v-else>--</span>
            </div>
          </template>

          <template #actions="data">
            <div class="text-nowrap">
              <feather-icon
                v-if="!data.item.appointment_ref_id"
                @click="
                  startAppointmetRequest(
                    data.item.recording_media_id,
                    data.item.appointment_id
                  )
                "
                icon="ArrowUpRightIcon"
                class="mx-1"
                size="16"
              />
            </div>
          </template>
        </Table>

        <b-pagination
          v-model="currentePage"
          @change="getLoadMore"
          :total-rows="rows"
          :per-page="size"
          v-if="rows > 1"
          first-number
          last-number
          align="center"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </view--c>
    </viewcard--c>
  </div>
</template>

<script>
import _appointmentRequestsService from "@/services/audiovisual/appointment-requests";

import _companyService from "@/services/company-service";
import _projectsService from "@/services/projects-service";
import _teachersService from "@/services/teachers-service";

import Table from "@/components/Table.vue";
import { BTooltip, VBPopover, BPopover } from "bootstrap-vue";

export default {
  components: {
    Table,
    BTooltip,
    BPopover,
  },
  directives: {
    "b-popover": VBPopover,
  },
  filters: {
    addZero: (value) => {
      if (!value) return "--";
      value = value + "";
      if (value.length > 1) return value;
      return `0${value}`;
    },
  },
  data() {
    return {
      isActiveFilter: false,
      btnew: null,
      isloading: false,
      loadingCompanies: false,
      loadingProjects: false,
      loadingTeacher: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "actions", label: "Ações" },
        { key: "teacher", label: "Professor", orderBy: { type: "back" } },
        { key: "subjects", label: "Assuntos" },
        { key: "project", label: "Projeto", orderBy: { type: "back" } },
        { key: "limit_date", label: "Prazo", orderBy: { type: "back" } },
        { key: "recommend_date", label: "Recomendação", orderBy: { type: "back" } },
        { key: "studio", label: "Estúdio Recomendado", orderBy: { type: "back" } },
        {
          key: "duration",
          label: "Duração Recomendada",
          orderBy: { type: "back" },
        },
        { key: "dsStatus", label: "Status", orderBy: { type: "back" } },
        {
          key: "appointment_ref_id",
          label: "Agendamento",
          orderBy: { type: "back" },
        },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: "",
        ascOrDes: true,
        orderByKey: null,
      },
      companies: [],
      companieSelected: [],
      projects: [],
      projectSelected: [],
      teachers: [],
      teachersSelected: [],
      status: [],
      statusSelected: [],
    };
  },
  created() {
    this.getRecords(this.currentePage);
    this.getStatus();
  },
  methods: {
    getStatus() {},
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      let _filter = {
        orderByKey: this.orderByParams.orderByKey,
        ascOrDes: this.orderByParams.ascOrDes,
      };

      _appointmentRequestsService
        .show(_page, _filter)
        .then((res) => {
          this.isActiveFilter = false;
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    totalSubs(files) {
      return files.length;
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.rows = 1;
      this.list = [{ page: 0, itens: [] }];
      this.orderByParams.search = this.search;
      this.getRecords(this.currentePage);
    },
    filterClear() {
      this.projectSelected = [];
      this.companieSelected = [];
      this.teachersSelected = [];
      this.statusSelected = [];
      this.list = [{ page: 0, itens: [] }];
      this.getRecords(this.currentePage);
    },
    getCompanies(_search) {
      if (_search && _search.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.loadingCompanies) {
            this.loadingCompanies = true;
            _companyService
              .autoComplete(_search)
              .then((res) => {
                this.companies = res.content.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                  };
                });
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.loadingCompanies = false));
          }
        }, 500);
      }
    },
    getProjects(_search) {
      if (_search && _search.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.loadingProjects) {
            this.loadingProjects = true;
            _projectsService
              .autoComplete(_search)
              .then((res) => {
                this.projects = res.content.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                  };
                });
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.loadingProjects = false));
          }
        }, 500);
      }
    },
    getTeachers(_search) {
      if (_search && _search.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.loadingTeacher) {
            this.loadingTeacher = true;
            _teachersService
              .autoComplete(_search)
              .then((res) => {
                this.teachers = res.content.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                  };
                });
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.loadingTeacher = false));
          }
        }, 500);
      }
    },
    startAppointmetRequest(pending_id, appointment_id) {
      this.$router.push({
        name: "pedagogic-appointment-new-save",
        query: { pid: pending_id },
        params: { id: appointment_id | 0 },
      });
    },
  },
};
</script>
<style>
.btn-filter-appoitment {
  position: fixed;
  bottom: 5%;
  right: 45px;
  z-index: 99;
}

.vselect-pending .custom-switch .custom-control-label::before {
  background-color: var(--warning);
}

.switch-icon-right {
  color: #fff;
}
</style>